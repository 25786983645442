<template>
    <div>
        <!-- 询价详情 -->
        <div>
            <InquiryDetails_Component></InquiryDetails_Component>
            <Footer_Compontent></Footer_Compontent>
        </div>
    </div>
</template>

<script>
import InquiryDetails_Component from '../../components/EnquiryCabinet/InquiryDetails_Component.vue';
import Footer_Compontent from '../../components/Footer_Compontent.vue';
export default {
    name: "InquiryDetails",
    components: { InquiryDetails_Component, Footer_Compontent }
}
</script>

<style lang="scss" scoped>
.footer {
    margin-top: 82px;
}
</style>